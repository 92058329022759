<template>
    <q-dialog ref="dialogRechercheRapide">
        <q-card style="width: 90%; max-width: 90vw">
            <q-card-section class="row items-center q-pb-none justify-between">
                <div class="col-1"></div>
                <q-tabs v-model="tab" class="col-10">
                    <q-tab name="rapide" label="Recherche rapide" />
                    <q-tab name="avancee" label="Recherche avancée" />
                </q-tabs>
                <div class="col-1">
                    <q-btn icon="close" flat round dense v-close-popup class="float-right"></q-btn>
                </div>
            </q-card-section>

            <q-card-section class="row q-gutter-x-sm justify-center">
                <q-input
                    v-if="tab === 'rapide'"
                    v-model="txtRechercheRapide"
                    ref="txtRechercheRapide"
                    class="col-2"
                    :label="typeRechercheRapide === 1 ? '# Contrat' : '# Téléphone'"
                    :disable="tab === 'avancee'"
                    @keyup.enter="rechercheRapide"
                    v-focus
                    outlined
                    hide-bottom-space
                    dense
                />
                <q-btn-toggle
                    v-model="typeRechercheRapide"
                    :options="typesRechercheRapide"
                    @update:model-value="typeRechercheRapideChanged"
                    toggle-color="primary"
                    color="white"
                    text-color="primary"
                    class="toggle-types-recherche-rapide"
                    no-caps
                    unelevated
                />
            </q-card-section>

            <div v-if="tab === 'avancee'">
                <e-recherche-avancee-contrat
                    v-if="typeRechercheRapide === 1"
                    v-model:no-contrat="noContrat"
                    v-model:prenom="prenomClient"
                    v-model:no-modele="noModele"
                    v-model:nom="nomClient"
                    v-model:no-unite="noUnite"
                    v-model:vendeur="vendeur"
                    v-model:compagnie="compagnie"
                    v-model:bureau="bureau"
                    v-model:approbateur="approbateur"
                    v-model:dessinateur="dessinateur"
                    v-model:autre-intervenant="autreIntervenant"
                    v-model:adresse="adresse"
                    v-model:notes-plan="notesPlan"
                    v-model:caracteristiques-plan="caracteristiquesPlan"
                    @keyup.enter="rechercheRapide"
                    @ouvrir-caracteristiques-plan="afficherCaracteristiquesPlan = true"
                />

                <e-recherche-avancee-prospect
                    v-if="typeRechercheRapide === 2"
                    v-model:telephone="noTelephone"
                    v-model:code-postal="codePostal"
                    v-model:nom="nomClientProspect"
                    v-model:compagnie="compagnie"
                    v-model:prenom="prenomClientProspect"
                    v-model:courriel="courriel"
                    @keyup.enter="rechercheRapide"
                />

                <ECaracteristiquesPlan
                    v-model="afficherCaracteristiquesPlan"
                    v-model:caracteristiques-plan="caracteristiquesPlan"
                    ref="dialogCaracteristiquesPlan"
                ></ECaracteristiquesPlan>
            </div>

            <q-card-section class="row justify-center">
                <q-btn color="primary" label="Rechercher" @click="rechercheRapide()" :disable="rechercheRapideDisabled" />
            </q-card-section>

            <q-table
                v-if="typeRechercheRapide === 1 && contrats.length"
                :title="`${contrats.length} contrat(s) trouvé(s)`"
                :rows="contrats"
                :columns="contratsColumns"
                row-key="idContrat"
                @row-click="ouvrirContrat"
            />

            <q-table
                v-if="typeRechercheRapide === 2 && prospects.length"
                :title="`${prospects.length} client(s) trouvé(s)`"
                :rows="prospects"
                :columns="prospectsColumns"
                row-key="idProspect"
                @row-click="ouvrirFicheProspect"
            />
        </q-card>
    </q-dialog>
</template>

<script>
import { useProspectStore } from "@/stores";

import ERechercheAvanceeContrat from "../../../components/ERechercheAvanceeContrat.vue";
import ERechercheAvanceeProspect from "../../../components/ERechercheAvanceeProspect.vue";
import ECaracteristiquesPlan from "../../../components/ECaracteristiquesPlan.vue";

export default {
    name: "e-dialog-recherche-rapide",
    components: {
        ERechercheAvanceeContrat,
        ERechercheAvanceeProspect,
        ECaracteristiquesPlan
    },
    data() {
        return {
            typeRechercheRapide: 1,
            typesRechercheRapide: [
                { label: "Contrat", value: 1 },
                { label: "Client", value: 2 }
            ],
            txtRechercheRapide: "",
            contratsColumns: [
                {
                    name: "numContrat",
                    label: "# Contrat",
                    align: "left",
                    field: "numContrat",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "client",
                    label: "Client(s)",
                    align: "left",
                    field: "clients",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "villeConstruction",
                    label: "Ville construction",
                    align: "left",
                    field: "villeConstruction",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "numModele",
                    label: "# Modèle",
                    align: "left",
                    field: "numeroModele",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "vendeur",
                    label: "Vendeur",
                    align: "left",
                    field: "vendeur",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                },
                {
                    name: "etat",
                    label: "État",
                    align: "left",
                    field: "etatContrat",
                    sortable: true,
                    classes: this.getCouleurEtatContrat
                }
            ],
            prospectsColumns: [
                {
                    name: "nomClient",
                    label: "Client",
                    align: "left",
                    field: "nomClient",
                    sortable: true
                },
                {
                    name: "nomCompagnie",
                    label: "Compagnie",
                    align: "left",
                    field: "nomCompagnie",
                    sortable: true
                },
                {
                    name: "telephone",
                    label: "# Téléphone",
                    align: "left",
                    field: "telephone",
                    sortable: true
                },
                {
                    name: "ville",
                    label: "Ville",
                    align: "left",
                    field: "ville",
                    sortable: true
                },
                {
                    name: "inscritPar",
                    label: "Conseiller",
                    align: "left",
                    field: "inscritPar",
                    sortable: true
                }
            ],
            contrats: [],
            prospects: [],
            noContrat: null,
            noModele: null,
            noUnite: null,
            nomClient: null,
            prenomClient: null,
            autreIntervenant: null,
            vendeur: null,
            bureau: null,
            approbateur: null,
            dessinateur: null,
            adresse: null,
            notesPlan: null,
            caracteristiquesPlan: [],
            noTelephone: null,
            codePostal: null,
            nomClientProspect: null,
            prenomClientProspect: null,
            compagnie: null,
            courriel: null,
            tab: "rapide",
            afficherCaracteristiquesPlan: false
        };
    },
    computed: {
        rechercheRapideDisabled() {
            if (this.tab === "rapide" && !this.txtRechercheRapide) {
                return true;
            } else if (
                this.tab === "avancee" &&
                this.typeRechercheRapide === 1 &&
                !this.noContrat &&
                !this.noModele &&
                !this.noUnite &&
                !this.nomClient &&
                !this.prenomClient &&
                !this.autreIntervenant &&
                !this.vendeur &&
                !this.bureau &&
                !this.approbateur &&
                !this.dessinateur &&
                !this.adresse &&
                !this.notesPlan &&
                !this.caracteristiquesPlan
            ) {
                return true;
            } else if (
                this.tab === "avancee" &&
                this.typeRechercheRapide === 2 &&
                !this.noTelephone &&
                !this.compagnie &&
                !this.nomClientProspect &&
                !this.prenomClientProspect &&
                !this.codePostal &&
                !this.courriel
            ) {
                return true;
            }

            return false;
        }
    },
    methods: {
        typeRechercheRapideChanged() {
            setTimeout(() => {
                this.txtRechercheRapide = "";
                if (this.tab === "rapide") {
                    this.$refs.txtRechercheRapide.focus();
                }
            }, 20);
        },
        async rechercheRapide() {
            if (!this.rechercheRapideDisabled) {
                this.$q.loading.show();

                try {
                    this.contrats = [];
                    this.prospects = [];

                    var cpTemp =
                        this.caracteristiquesPlan != null
                            ? this.caracteristiquesPlan
                                  .map((element) => {
                                      return element.IdTagCaracteristiqueDessin;
                                  })
                                  .join("|")
                            : null;

                    if (this.typeRechercheRapide === 1) {
                        this.contrats = await this.$api.contrats.getContratsPourRechercheRapide({
                            noContrat: ((this.tab === "avancee" ? this.noContrat : this.txtRechercheRapide) || "").trim() || null,
                            noModele: this.noModele || null,
                            noUnite: this.noUnite || null,
                            nomClient: this.nomClient || null,
                            prenomClient: this.prenomClient || null,
                            nomCompagnie: this.compagnie || null,
                            vendeur: this.vendeur || null,
                            bureau: this.bureau || null,
                            approbateur: this.approbateur || null,
                            dessinateur: this.dessinateur || null,
                            autreIntervenant: this.autreIntervenant || null,
                            adresse: this.adresse || null,
                            notesPlan: this.notesPlan || null,
                            caracteristiquesPlan: cpTemp
                        });

                        if (this.contrats.length === 0) {
                            this.$q.notify({ type: "warning", message: "Aucun contrat trouvé." });
                        } else if (this.contrats.length === 1) {
                            this.ouvrirContrat(null, this.contrats[0]);
                        }
                    } else {
                        this.prospects = await this.$api.prospects.getProspectsPourRechercheRapide({
                            noTelephone: (this.tab === "avancee" ? this.noTelephone : this.txtRechercheRapide) || null,
                            nomClient: this.nomClientProspect || null,
                            prenomClient: this.prenomClientProspect || null,
                            codePostal: this.codePostal || null,
                            courriel: this.courriel || null,
                            nomCompagnie: this.compagnie || null
                        });

                        if (this.prospects.length === 0) {
                            this.$q.notify({ type: "warning", message: "Aucun client trouvé." });
                        } else if (this.prospects.length === 1) {
                            this.ouvrirFicheProspect(null, this.prospects[0]);
                        }
                    }
                } catch (error) {
                    this.$q.notify({ type: "negative", message: "Une erreur est survenue lors de la recherche." });
                } finally {
                    this.$q.loading.hide();
                }
            }
        },
        getCouleurEtatContrat(c) {
            return c.etat == 2 ? "text-red-7" : c.etat == 3 ? "text-orange-7" : c.etat == 4 ? "text-green-7" : "text-blue-7";
        },
        ouvrirContrat(evt, row) {
            this.$refs.dialogRechercheRapide.hide();
            this.$router.push({
                name: "AncienneErp",
                params: this.$utils.getParamsAncienneErp({
                    url: "FicheContrat.aspx",
                    data: {
                        idContrat: row.idContrat
                    }
                })
            });
        },
        ouvrirFicheProspect(evt, row) {
            this.$refs.dialogRechercheRapide.hide();

            useProspectStore().estRechercheParTelephone = (this.noTelephone || (!isNaN(this.txtRechercheRapide) ? this.txtRechercheRapide : null) || "").length === 10;

            this.$router.push({
                name: "Prospect",
                params: {
                    idProspect: row.idProspect
                }
            });
        },
        reset() {
            this.typeRechercheRapide = 1;
            this.txtRechercheRapide = "";
            this.contrats = [];
            this.prospects = [];
            this.tab = "rapide";
            this.noContrat = null;
            this.noModele = null;
            this.noUnite = null;
            this.nomClient = null;
            this.prenomClient = null;
            this.autreIntervenant = null;
            this.vendeur = null;
            this.bureau = null;
            this.approbateur = null;
            this.dessinateur = null;
            this.adresse = null;
            this.notesPlan = null;
            this.caracteristiquesPlan = [];
            this.noTelephone = null;
            this.codePostal = null;
            this.nomClientProspect = null;
            this.prenomClientProspect = null;
            this.compagnie = null;
            this.courriel = null;
        },
        resetProspect() {
            this.noTelephone = null;
            this.codePostal = null;
            this.nomClientProspect = null;
            this.prenomClientProspect = null;
            this.compagnie = null;
            this.courriel = null;
        }
    },
    directives: {
        focus: {
            mounted(el) {
                el.focus();
            }
        }
    }
};
</script>

<style scoped>
.toggle-types-recherche-rapide {
    border: 1px solid #027be3;
}
</style>
